// $(document).ready(function () {
(function ($) {
    $(window).on('load', function () {
        $('#status').fadeOut();
        $('#preloader').delay(300).fadeOut(500);
        $('body').delay(1000).css({
            'overflow': "visible"
        })
    })
    var $banner = $('.banner'),
        $testimonials = $('.testimonials'),
        $testimonialswp = $('.testimonials-wrapper'),
        $menusticky = $('#sticked-menu'),
        $openmenu = $('#menu-button'),
        $parentlink = $(".parent-link"),
        $menulevel = $('.menulevel'),
        $logo1 = $('#logo-1'),
        $logo2 = $('#logo-2'),
        $headerfloat = $('.header-float'),
        $statss = $("#stats"),
        $currentcate = $("#current-cate"),
        $orderbox = $("#orderbox"),
        $tablecart = $('#table-cart'),
        $cartrow = $('.cart-row'),
        $groupbtn = $('.button-group'),
        $mainmenu = $('.mainmenu'),
        $socialitem = $('.socials .social-item-1 a');


    // menu responsive
    var fisopen = 0;

    $openmenu.on('click', function () {
        if (fisopen == 0) {
            $mainmenu.stop().slideDown();
            $openmenu.removeClass('fa-reorder');
            $openmenu.addClass('fa-close');
            fisopen = 1;
        }
        else {
            $mainmenu.slideUp();
            $openmenu.removeClass('fa-close');
            $openmenu.addClass('fa-reorder');
            $menulevel.stop().slideUp();
            $menulevel.prev().removeClass("ropen");
            fisopen = 0;
        }
    });

    var $menulevel = $('.menulevel');
    $parentlink.on("click", function (e) {
        e.preventDefault();
        var $children = $(this).next(),
            parents = $(this).parents($menulevel),
            pcur = $($menulevel).not(parents);
        pcur.slideUp(500);
        pcur.prev().removeClass("ropen");
        var cur = $children.filter(":hidden");
        cur.slideDown(500);
        cur.prev().addClass("ropen");
    });

    $(window).on('resize', function () {
        if ($(window).width() > 1224) {
            if(fisopen ==0 ) {
                $mainmenu.show();
                fisopen = 1;
                $menulevel.show();
            }
        }
        else {
            if(fisopen) {
                $mainmenu.hide();
                $menulevel.hide();
                $menulevel.prev().removeClass("ropen");
                fisopen = 0;
                $openmenu.removeClass('fa-close');
                $openmenu.addClass('fa-reorder');
            }
        }

    }).resize();


    //parallax background
    if ($banner.length) {
        $banner.parallax(this["image-src"]);
        $(window).trigger('resize').trigger('scroll');
    }
    if ($testimonialswp.length) {
        $testimonialswp.parallax(this["image-src"]);
        $(window).trigger('resize').trigger('scroll');
    }

    //testimonial carousel
    if ($testimonials.length) {
        var $owl = $('.owl-carousel');
        $owl.owlCarousel({
            items: 1,
            loop: true,
            center: true,
            dots: false,
            autoplay: true,
            nav: false,
            autoplayHoverPause: true
        });
        $('.prev-btn').on('click', function () {
            $owl.trigger('prev.owl.carousel', [400]);
        });
        $('.next-btn').on('click', function () {
            $owl.trigger('next.owl.carousel', [400]);
        })
    }
    //Menu sticky
    var sticky = null,
        sticked = false,
        a = 0;
    if ($headerfloat.length) a = 1;
    if ($menusticky.length) {
        $(window).on('scroll', function () {

            if ($(window).scrollTop() > 250 && sticked == false) {
                sticky = new Waypoint.Sticky({
                    element: $menusticky,
                })
                if (a == 1) {
                    $logo1.css({
                        'display': 'table'
                    });
                    $logo2.hide();
                    $openmenu.css({
                        'color': '#052035'
                    });
                    $socialitem.css({
                        'color': '#052035'
                    })
                }

                sticked = true;
                $menusticky.addClass('animated slideInDown');
            }
            if ($(window).scrollTop() < 250 && sticked == true) {
                sticky.destroy();
                sticked = false;
                $menusticky.removeClass('animated slideInDown');
                if (a == 1) {
                    $logo2.css({
                        'display': 'table'
                    });
                    $logo1.hide();
                    $openmenu.css({
                        'color': '#d3d3d3'
                    })
                    $socialitem.css({
                        'color': '#d3d3d3'
                    })
                }
            }
        });
    }
    // Couting stats waypoint
    if ($statss.length)
        var $stats = new Waypoint({
            element: $statss,
            handler: function () {
                $('.count').each(function () {
                    $(this).prop('Counter', 0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });
                this.destroy()
            },
            offset: 'bottom-in-view'
        })

    // Ordering
    if ($currentcate.length) {
        $currentcate.on('click', function () {
            $orderbox.stop().slideToggle();
        })
        $(".nav-link ").on('click', function () {
            $orderbox.fadeOut();
            $currentcate.html($(this).html());
        })
    }
    //Table cart
    if ($tablecart.length) {
        $quantity = $(".quantity");
        $quantity.find('.add').on('click', function () {
            var temp = Number($(this).siblings(".quantity-num").html()) + 1;
            $(this).siblings(".quantity-num").html(temp.toString());

        })
        $quantity.find('.minus').on('click', function () {
            var temp = Number($(this).siblings(".quantity-num").html()) - 1;
            if (temp >= 1) {
                $(this).siblings(".quantity-num").html(temp.toString());
            }

        })
        $cartrow.each(function () {
            var $elem = $(this),
                $rmbtn = $elem.find('.remove-btn');
            $rmbtn.on('click', function () {
                $elem.remove();
            })
        });


    }
    ;
    $projectsisotope = $('#projects-isotope');
    if ($projectsisotope.length) {
        var $grid = $('.grid').isotope({
            itemSelector: '.grid-item',
            percentPosition: true,
        });
        $grid.imagesLoaded().progress(function () {
            $grid.isotope('layout');
        });
        // Filter button
        $groupbtn.on('click', '.aubtn', function (e) {
            e.preventDefault();
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({filter: filterValue});
        });
        // change is-checked class on buttons
        $groupbtn.each(function (i, buttonGroup) {
            var $buttonGroup = $(buttonGroup);
            $buttonGroup.on('click', '.aubtn', function () {
                $buttonGroup.find('.is-checked').removeClass('is-checked');
                $(this).addClass('is-checked');
            })
        });

    }
})($);