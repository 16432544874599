$(document).ready(function () {
    var $slider1 = $('#rev-slider-1'),
        $slider2 = $('#rev-slider-2');
        $slider3 = $('#rev-slider-3');
    if($slider1.length)
    $slider1.revolution({
        sliderLayout: 'auto',
        sliderType: "standard",
        delay: 3500,
        navigation: {
            arrows: {
                enable: true,
                hide_onleave: false
            },
            bullets: {
                enable: false
            }
        },
        spinner: 'spinner3',
        lazyType: "single",
        responsiveLevels: [1200, 991, 769, 480],
        gridwidth:[1170, 991, 769, 480],
        // gridwidth: 1170,
        gridheight: [500,500,500,400]
    });
    if($slider2.length)
    $slider2.revolution({
        sliderLayout: 'auto',
        sliderType: "standard",
        delay: 3500,
        navigation: {
            arrows: {
                enable: false
            },
            bullets: {
                enable: false
            }
        },
        spinner: 'spinner3',
        lazyType: "single",
        responsiveLevels: [1200, 991, 769, 480],
        gridwidth:[1170, 991, 769, 480],
        gridheight: [976,976,976,500]
    });
    if($slider3.length)
    $slider3.revolution({
        sliderLayout: 'auto',
        sliderType: "standard",
        delay: 3500,
        navigation: {
            arrows: {
                enable: true
            },
            bullets: {
                enable: false
            }
        },
        spinner: 'spinner3',
        lazyType: "single",
        responsiveLevels: [1200, 991, 778, 480, 320],
        gridwidth:[1170, 991, 778, 480, 320],
        gridheight: [672,672,672,500]
    });
});